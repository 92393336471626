/**
 * Timetastic
 * Features > Leave Types page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import FeaturesCrossLinks from "src/components/featuresCrossLinks"
import FeaturesQuotes from "src/components/featuresQuotes"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SignupButton from "src/components/signupButton"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/features.scss"

// SEO
const title = "Recording absence from work using Timetastic"
const description =
  "Record all types of absence; holidays, vacations, sickness, maternity, doctors, sabbaticals, + all custom reasons to align with your company policy."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} useTitleTemplate={false} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-features-main">
          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="c-features-intro is-unanimated">
                <div className="c-features-intro__description">
                  <h1 className="h h2 c-features-intro__title">Leave Types</h1>
                  <p className="c-features-intro__summary">
                    Book, track, and record all the different types of absence
                    from work.
                  </p>
                  <p>
                    Holidays, sickness, maternity, sabbaticals, or custom leave
                    types that align with your company policies - it’s all
                    covered in Timetastic.
                  </p>
                  <p>
                    <SignupButton buttonCopy="Start a free trial today" trackCtaLabel="FeaturesSignUp" />
                  </p>
                </div>
                <div className="c-features-intro__media-lt">
                  <img
                    loading="eager"
                    alt="Annual Leave"
                    className="c-features-intro__image"
                    src="/images/features/leave-types/leave-hero-amrita.png"
                  />{" "}
                  <img
                    loading="eager"
                    alt="Sickness"
                    className="c-features-intro__image"
                    src="/images/features/leave-types/leave-hero-dakarai.png"
                  />{" "}
                  <img
                    loading="eager"
                    alt="Maternity Leave"
                    className="c-features-intro__image"
                    src="/images/features/leave-types/leave-hero-sophie.png"
                  />
                </div>
              </div>
            </div>
          </section>

          <FeaturesQuotes
            quotes={[
              {
                avatar:
                  "/images/features/leave-types/darryl-adie-ampersand.jpg",
                company: "Ampersand",
                name: "Darryl Adie",
                quote:
                  "We track vacations, sickness, family days. It’s great to get visibility like this.",
              },
              {
                avatar: "/images/features/leave-types/chris-smith-custard.jpg",
                company: "Custard",
                name: "Chris Smith",
                quote:
                  "Best thing about Timetastic, I’m now always up to date without any effort.",
              },
            ]}
          />

          <section className="c-section c-features-xyz">
            <div className="u-inner u-inner--l">
              <div className="c-features-grid__item">
                <div className="c-features-grid__media c-features-grid__media-lt-1">
                  <img
                    loading="eager"
                    alt="Absence booking"
                    className="c-features-grid__image"
                    src="/images/features/leave-types/annual-leave-01.png"
                    width={539}
                    height={120}
                  />
                  <img
                    loading="lazy"
                    alt="Deductible and non-deductible leave"
                    className="c-features-grid__image"
                    src="/images/features/leave-types/annual-leave-02.png"
                    width={262}
                    height={240}
                  />
                  <img
                    loading="lazy"
                    alt="This booking deducts from allowance"
                    className="c-features-grid__image"
                    src="/images/features/leave-types/annual-leave-03.png"
                    width={262}
                    height={147}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Holidays and Annual Leave
                  </h2>
                  <p className="c-features-grid__title">
                    Set your annual leave allowance and start booking time off
                  </p>
                  <p>
                    Once you’ve set an annual entitlement you can book and track
                    absences against it.
                  </p>
                  <p>
                    As you book time off, you have a full record and everything
                    is kept bang up to date.
                  </p>
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Custom Leave Types
                  </h2>
                  <p className="c-features-grid__title">
                    Keep track of any type of absence you need
                  </p>
                  <p>
                    Create custom reasons for absence - as many as you need. All
                    will be visible on the employees calendar and summary, you
                    can download to excel and report on the different reasons
                    for absence.
                  </p>
                </div>
                <div className="c-features-grid__media">
                  <ul className="u-tag-list">
                    <li className="">Annual Leave</li>
                    <li className="">Sickness</li>
                    <li className="">Maternity/Paternity</li>
                    <li className="">Sabbatical</li>
                    <li className="">Doctors Appointment</li>
                    <li className="">Seasonal Shutdown</li>
                    <li className="">Compassionate</li>
                    <li className="">Away Day</li>
                    <li className="">Training</li>
                  </ul>
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Public holidays from 250 countries"
                    className="c-features-grid__imagex"
                    src="/images/features/leave-types/public-holidays-map.png"
                    width="539"
                    height="281"
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Public Holidays
                  </h2>
                  <p className="c-features-grid__title">
                    Public and national holidays all catered for
                  </p>
                  <p>
                    Public holidays from over 250 countries and 3,000+ regions
                    around the world.
                  </p>
                  <p>You’re pretty well covered in Timetastic :)</p>
                </div>
              </div>
            </div>
          </section>

          <FeaturesCrossLinks hidden="Leave Types" />

          <FreeTrialAlt
            body="If you’re still wondering about something in Timetastic, or need to see how something works. Just shout, we’re here to help."
            buttonCopy="Start free trial today"
            title="If you’re ready, give it a try now"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default;
